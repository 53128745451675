<template>
    <div class="d-flex flex-column h-100">
        <header class="shadow-sm">
            <nav class="navbar navbar-expand-lg navbar-light bg-purple">
                <div class="container ">
                    <a class="navbar-brand" href="#">
                        <img src="logo_miti.png" alt="Logo">
                    </a>    
                    <b-dropdown right class="custom-dropdown">
                        <template #button-content>
                            <img src="PersonFilled.svg" alt="Descripción de la imagen" width="50" height="50" />
                        </template>
                        <b-dropdown-item @click="logout()">
                            Cerrar Sesión
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </nav>
        </header>
        <div class="container h-100 py-4">
            <div class="row justify-content-center h-100">
                <div :class="{ 'col-xs-12 col-sm-12 col-md-8': history !== null, 'col-xs-12 col-sm-12 col-md-10': history === null }" class=" p-1">
                    <div class="card chat-card shadow border-2 d-flex flex-column justify-content-between chat-card-border-gray chat-card-rounded">
                        <div class="card-header text-dark p-4">
                            <h2 class="mb-0">Copiloto MITI</h2>
                        </div>
                        <div class="card-body h-100">
                            <div class="message-box" ref="scrollContainer" id="chat" v-if="history" v-scroll-bottom >
                                
                                <div v-for="item in history" :key="item.analisis" >
                                    
                                    <div class="message2 mb-2">
                                        <div class="d-flex justify-content-end">
                                            <p class="small mb-1">User</p>
                                        </div>
                                        <div class="d-flex flex-row justify-content-end mb-1 pt-1">
                                            <div>
                                              <p class="small p-2 me-3 mb-3 text-white " style="background-color: #333333;border-radius:10px">{{  item.question  }}</p>
                                            </div>
                                            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
                                              alt="avatar 1" style="width: 45px; height: 100%;">
                                        </div>
                                        <div class="d-flex justify-content-end " style="padding-right: 3rem;">
                                            <p class="small text-muted">{{ item.question_date  }}</p>
                                        </div>
                                    </div>

                                    <div class="message">
                                        <div class="d-flex justify-content-start">
                                            <p class="small mb-1">MITI</p>
                                        </div>
                                        <div class="d-flex flex-row justify-content-start">
                                            <img src="bot.svg"
                                            alt="avatar 1" style="width: 45px; height: 100%;">
                                            <div>
                                                <div class="small p-2 ms-3 mb-3 pre-text" style="background-color: #f5f6f7;border-radius:10px">{{ item.analisis!=='' ? item.analisis : 'Cargando...' }}</div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-start " style="padding-left: 3rem;">
                                            <p class="small text-muted">{{ item.analisis_date ? item.analisis_date : '' }}</p>
                                        </div>
                                    </div>      
                                </div>
                            </div>
                        </div>
                        
                        <div class="card-footer text-center" style="" >
                            <div class="row justify-content-center ">
                                <div class="col-12 ">
                                    <form @submit.prevent="getAnswer">
                                        <div class="input-group " id="message-input">
                                            <input 
                                                v-model="question"
                                                ref="inputQuestion"
                                                type="text" 
                                                :disabled="isSending"
                                                @keydown="handleKeyDown"
                                                class="form-control" 
                                                placeholder="Envia un mensaje..." 
                                                style="height: 4.5rem; border-radius: 0.6rem; box-sizing: border-box;">
                                            <div class="input-group-prepend">
                                              <button 
                                              :disabled="!question || isSending"
                                              style="z-index: 1000;"
                                              class="btn  button-chat" type="submit"
                                              ><b-spinner v-if="isSending" small type="grow" label="Cargando..."></b-spinner></button>
                                            </div>
                                        </div>
                                    </form>
                                    
                                </div>
                            </div>
                            <small class="font-weight-bolder">ChatGPT puede producir información inexacta sobre personas, lugares o hechos.</small>
                        </div>
                    </div>
                </div>
                
                <div v-if="history" class="col-xs-12 col-sm-12 col-md-4 p-1">
                    <div   class="card chat-card shadow border-2 chat-card-border-gray chat-card-rounded">
                        <div class="card-header p-4 custom-card-header">
                            <h2 class="mb-0 text-dark bg-white">Documentación vinculada</h2>
                        </div>
                        <div class="card-body h-100">
                            <div class="row documents-box" v-if="items && items.length > 0">
                                <div class="col-12 mb-4" v-for="(item, index) in items" :key="index">
                                    <div class="card shadow border-2 chat-card-border-gray" style="border-radius: 0.7rem !important;">
                                        <div class="card-body">
                                            <h4>{{ item.ItemTitle }}</h4>
                                            <span class="text-muted mt-3 mb-4">{{ item.WorkspaceName }}</span>
                                            <!-- <p class="mb-2"> {{ item.Detalle }}</p> -->
                                            <div class="text-right">

                                                <b-button @click="toToReference(item.Url)"  variant="primary">Ir</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
        
    </div>
</template>
  
<script lang="js">


import axios from '@/axios'
import Authorization from '@/authorization'



export default {
    data() {
        return {
            question: '',
            question_old: '',
            answer: null,
            analisis: '',
            history: null,
            pdf: {},
            isSending: false,
            isMobile: false,
            isMedium: false,
            lastMessageAnalisis: null,
        }
    },
    title: 'Copiloto Karibu',
    methods: {
        logout(){
            Authorization.removeAccessToken()
            this.$router.push("/login")
        },
        handleKeyDown(event) {
            if (event.keyCode === 13 && this.isSending) {
                event.preventDefault()
            }
        },
        toToReference(url) {
            window?.gtag('event', 'click_reference', {
             'url': url   
            });
            window.open(url, '_blank');
        },
        getDate() {
            const now = new Date();
            const day = now.getDate().toString().padStart(2, '0');
            const month = (now.getMonth() + 1).toString().padStart(2, '0');
            const year = now.getFullYear().toString();
            const hours = now.getHours();
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const meridian = hours >= 12 ? 'pm' : 'am';
            const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');

            const formattedDate = `${day}/${month}/${year} ${formattedHours}:${minutes} ${meridian}`;

            return formattedDate;
        },
        async getAnswer() {
            try {
                window?.gtag('event', 'send_message');
                this.isSending = true;
                
                if (this.history == null) {
                    this.history = []
                }

                const response = await axios.post('/search-answer',
                    {
                        question: this.question,
                        history: this.history
                    })

                const fecha_pregunta = this.getDate();
                
                this.history.push({ question: this.question, analisis: '', question_date: fecha_pregunta, analisis_date: '' });
                this.$nextTick(() => {
                    this.$refs.scrollContainer.scrollTop = this.$refs.scrollContainer.scrollHeight;
                });
                this.isSending = false
                if (response.status === 401) {
                    Authorization.removeAccessToken()
                    this.$router.push("/login")
                }
                this.answer = response.data.answer.answer
                this.analisis = response.data.answer.analisis
                this.question_old = this.question
                this.history = this.history.map( item => {
                    if (item.question === this.question_old) {
                        item.analisis = this.analisis
                        item.analisis_date = this.getDate()
                    }
                    return item
                });
                this.question = '';
                // this.history.push({ question: this.question_old, analisis: this.analisis, question_date: fecha_pregunta })
                this.$nextTick(() => {
                    this.$refs.scrollContainer.scrollTop = this.$refs.scrollContainer.scrollHeight;
                });
            } catch (error) {
                window?.gtag('event', 'error_send_message');
                this.$bvToast.toast('Por favor, recargue la página e intente nuevamente', {
                    title: `Ocurrió un error al enviar la pregunta`,
                    variant: 'danger',
                    solid: true
                })
                this.isSending = false
            }
        }
    },
    computed: {
        /* reversedList() {
            
            return this.history.slice()
        }, */
        
        ruta() {
            return 'pdf/' + this.pdf.pdf;
        },
        items() {
            return this.answer;
        },
    },
    
    mounted() {
        this.isMobile = window.innerWidth < 576;
        this.isMedium = window.innerWidth >= 576 && window.innerWidth < 768;
        
    },
    directives: {
    'scroll-bottom': {
      inserted: function(el) {
        el.scrollTop = el.scrollHeight;
      },
      update: function(el) {
        el.scrollTop = el.scrollHeight;
      }
    }
    },
}
</script>
  
<style>
.container_div {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 5px;
}

.input-field {
    border: none;
    outline: none;
    width: 100%;
    padding: 0 10px;
}
.bg-purple {
    background-color: #453952 !important;
}
.shadow-sm {
	box-shadow: 0 0rem 4rem rgba(9, 5, 5, 0.57) !important;
}
.chat-card {
    min-height: 100%;
}
.chat-card-rounded {
    border-radius: 1.3rem !important;
}
.chat-card-border-gray {
    border-color: #B5B5B5 !important;
    
}

.custom-card-header {
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
	background-color: rgba(255, 255, 255, 0.2);
	border-bottom: 0;
}


.button-chat {
    margin: 0.8rem -6rem 0.2rem;
    height: 3rem;
    width: 5rem;
    z-index: 100;
    background-image: url("../../public/send-message.svg") !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.div-input-message-desktop {
    position: relative;
    display: flex;
    justify-content: center;
    width: 90%; 
}

.div-input-message-mobile {
    position: relative;
    display: flex;
    justify-content: center;
    width: 70%; 
}

.form-control:focus-visible {
    
    background-color: #fff;
    border-color: #33BFD8;
    border: 1px solid #33BFD8;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(85, 171, 218, 0.5);
  }
  .message-box {
      overflow-y: scroll;
      max-height: 45vh;
      margin: 0%;
      
      width: 100%;
      padding: 2rem;
      /* 
    
    padding-right: 1rem;
    */
  }

  .documents-box {
    max-height: 50vh;
    overflow-y: scroll;
    margin: 0%;
    width: 100%;
    padding: 2rem;
  }

  .dropdown-button-content {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .custom-dropdown {
    border: none;
    box-shadow: none;
  }
  
  .custom-dropdown .dropdown-toggle::after {
    display: none;
  }
  .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgb(120, 107, 135);
}
.btn-secondary:focus, .btn-secondary.focus {
	color: #fff;
	background-color: #453952;
	border-color: #453952;
	box-shadow: 0 0 0 0.2rem rgb(69, 57, 82);
}
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
        color: #fff;
        background-color: #453952;
        border-color: #453952;
    }
    .btn-secondary {
        color: #fff;
        background-color: #453952;
        border-color: #453952;
    }
    .input-group-prepend button {
        margin: 0.8rem -6rem 0.2rem;
        height: 3rem;
        width: 5rem;
        z-index: 100;
        background-image: url("../../public/send-message.svg") !important;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      
      .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .show-prepend .input-group-prepend {
        opacity: 1;
      }
@media (min-width: 1200px){
    .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 95%;
    }
}

@media (min-width: 576px){
    .container, .container-sm {
      max-width: 95%;
    }
}

.pre-text {
  white-space: pre-wrap;
}
</style>
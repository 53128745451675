<template>
    <div style="width: 100%;padding-right: 0;padding-left: 0;margin-right: 0;margin-left: 0;" >
        <div class="container-fluid" >
            <div class="row min-vh-100">
                <!-- Sección con la imagen a la derecha -->
                <div class="d-block bv-d-sm-down-none col-md-5 p-0 d-flex flex-column justify-content-center" >
                    <img  src="background-login.png" class="img-fluid" >
                </div>
                <!-- Sección con el formulario de login a la izquierda -->
                <div class="col-xs-12 col-sm-12 col-md-6 d-flex flex-column justify-content-start " :class="{ 'padding-margin-form-mobile': isMobile, 'padding-margin-form-desktop': !isMobile,  }" >
                    <!-- Agrega aquí los elementos del formulario de login -->
                    <div 
                        class="row d-flex justify-content-start align-items-center" 
                        
                        :class="{ 'padding-margin-icon-miti-mobile': isMobile, 'padding-margin-icon-miti-desktop': !isMobile,  }" 
                        >
                        <div class="col-xs-12 col-sm-12 col-md-7  text-center  " :style="!isMobile ? 'margin-left:5rem;' :''" >
                            <img src="logo_miti.png" class="img-fluid" width="45%" style="height: 100%;">
                        </div>
                    </div>
                    <div class="row d-flex justify-content-start align-items-center" >
                        <div class="col-xs-12 col-sm-12 col-md-7  " :style="!isMobile ? 'margin-left:5rem;' :''">
                            <h1 class="text-center display-5 text-secondary" >INICIAR SESIÓN</h1>
                            <div style="margin-top: 3rem;">
                                <b-form @submit="login" >
                                    <b-form-group 
                                        class="my-4"    
                                        id="username-input" 
                                        label-for="username"
                                    >
                                        <b-form-input 
                                            size="lg"
                                            autocomplete="off"
                                            id="username" 
                                            v-model="username" 
                                            required
                                            placeholder="Nombre de usuario"></b-form-input>
                                    </b-form-group>
                
                                    <b-form-group 
                                        id="password-input" 
                                        autocomplete="off"
                                        label-for="password">
                                        <b-form-input size="lg" id="password" v-model="password" type="password" required
                                            placeholder="Contraseña"></b-form-input>
                                    </b-form-group>
                                    <div class="text-right" style="margin-top: 3rem;">
                                        <b-button 
                                            type="submit" 
                                            class="mt-3 btn-block button-purple"
                                        >LOGIN</b-button>
                                    </div>
                                </b-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script lang="js">
import Authorization from '@/authorization';
import axios from '@/axios'
export default {
    data() {
        return {
            username: undefined,
            password: undefined,
            isMobile: false,
            isMedium: false,
        }
    },
    methods: {
        async login(e) {
            e.preventDefault();
            const response = await axios.post('/login', {
                email: this.username,
                password: this.password,
            });

            if (response.status !== 200) {
                console.warn('Error al ingresar: ', response.data)
                return;
            }
            console.debug('Inicio de sesión correcto');
            Authorization.setAccessToken(response.data.access_token)

            this.$router.push("/")
        }
    },
    mounted() {
        this.isMobile = window.innerWidth < 576;
        this.isMedium = window.innerWidth >= 576 && window.innerWidth < 768;
    }
}
</script>
  
<style scoped>
.container {
    margin-top: 50px;
}
.button-purple{
    background-color: #453952; 
    border-color: #453952;
    height: 60px;
    width: 100%;
    font-size: 1.2rem;
}
.button-purple:not(:disabled):not(.disabled):active, .button-purple:not(:disabled):not(.disabled).active, .show > .button-purple.dropdown-toggle {
    color: #fff;
    background-color: #453952;
    border-color: #453952;
  }
.button-purple:not(:disabled):not(.disabled):active:focus, .button-purple:not(:disabled):not(.disabled).active:focus, .show > .button-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 57, 82, 0.5);
}

.button-purple:focus, .button-purple.focus {
    color: #fff;
    background-color: #453952;
    border-color: #453952;
    box-shadow: 0 0 0 0.2rem rgba(69, 57, 82, 0.5);
  }



  .form-control:focus-visible {
    color: #514957;
    background-color: #fff;
    border-color: #453952;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(69, 57, 82, 0.5);
  }
  .padding-margin-form-desktop{
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
  .padding-margin-form-mobile{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .padding-margin-icon-miti-desktop{
    margin-top: 5rem; 
    margin-bottom: 2rem; 
  }
  .padding-margin-icon-miti-mobile{
    padding-top: 0;
    padding-bottom: 0;
  }
</style>
  
import axios from 'axios';
import Authorization from '@/authorization'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  validateStatus: function (status) {
    return status >= 200 && status < 500; // default
  }
});

axiosInstance.interceptors.request.use((config) => {
  const accessToken = Authorization.getAccessToken();

  if (accessToken !== undefined) {
    config.headers.Authorization = `${accessToken}`;
  }

  return config;
});

export default axiosInstance;

<template>
  <div  id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  title: 'Copiloto Karibu',
}
</script>
<style>
html, body, #app {
  height: 100%;
  width: 100%;
}
</style>
const checkIfUserIsLoggedIn = () => {
    const accessToken = getAccessToken()
    return accessToken !== undefined && accessToken !== null
}

const setAccessToken = (accessToken) => {
    localStorage.setItem("@access_token", accessToken)
}

const getAccessToken = () => {
    return localStorage.getItem("@access_token")
}

const removeAccessToken = () => {
    localStorage.removeItem("@access_token")
}

export default { checkIfUserIsLoggedIn, setAccessToken, getAccessToken, removeAccessToken }